import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { colors } from '../styles/theme';
import { TextBlock } from '../styles/shared';
import { storeReferralData } from '../utils/helpers';
import SEO from '../components/SEO';

const PrivacyPage = ({ location }) => {
  // store referral params
  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      storeReferralData(params);
    }
  }, []);
  return (
    <>
      <SEO title='Privacy Policy' />
      <Layout>
        <Container>
          <TextBlock>
            <h1>Privacy Policy</h1>
            <h3>UPDATED: April 25. 2021</h3>
            <p>
              Neu Community, Inc. (which, together with its builder and leasing
              affiliates, including Neu Communities, are collectively referred
              to herein as "Neu," "we," "us" or "our") provides this Privacy
              Policy. This Privacy Policy describes the collection, use and
              disclosure of information on this website (this "Website"), on our
              mobile sites, applications, and mobile interactive features
              ("Mobile Applications"), on our social media pages ("Social Media
              Pages"), in connection with email messages that we may send to
              you, and other interactions with you. This Website, our Mobile
              Applications and our Social Media Pages are collectively referred
              to in this Privacy Policy as our "Sites." As used herein, the
              words "user," "you," and "your" mean users of this Website or our
              other Sites. Throughout this Privacy Policy, where we refer to
              residents of a particular location, we mean individuals who are
              currently resident in that location as indicated by information
              regarding their place of residence that they have provided to us
              (such as through submission of an online form), or as otherwise
              made available to us.
            </p>
            <p>
              Where applicable, you consent to the use of your information as
              described in this Privacy Policy each time you access or use our
              Sites, so we urge you to read this document in full, and Contact
              Us if you have any questions.
            </p>
            <p>
              By creating an online Account on our Sites, you agree that you
              will keep your username and password secure and not share them
              with others. You understand and agree that creation and use of
              your online Account is subject to our Terms of Use, and that you
              are responsible for all activities that occur under your Account
              or password.
            </p>
            <h2>CATEGORIES OF PERSONAL INFORMATION WE COLLECT.</h2>
            <p>
              When you interact with Neu, we collect information from that
              interaction, for example, from your visits to and use of our
              Sites, from your email correspondence to us, from your submittal
              of an employment or rental application and our processing and
              review of those applications, from your online responses to us,
              from your completion of a registration card in one of our
              communities, from information that you post online, for example,
              on our Social Media Pages including, for example, Facebook,
              Twitter, LinkedIn, and Instagram and when you interact with our
              Mobile Applications. When this information is reasonably capable
              of being associated with you, we refer to this information as
              Personal Information.
            </p>
            <p>
              At Neu we may collect and share the following categories of
              Personal Information:
            </p>
            <h3>Identifiers.</h3>
            <p>
              Identifiers are information directly linked to a particular
              individual. For example, depending on the circumstance, Neu may
              collect your name, address, email address, or phone number as part
              of a home purchase agreement, a rental application and the related
              lease agreement or other interaction. Identifiers also include
              more sensitive information that may be gathered from you or others
              about you, including your social security number, driver's license
              number or insurance policy number, where that information is
              necessary in order to complete a transaction. If you buy or rent a
              home from us, or otherwise engage in a commercial transaction with
              us, we may also collect financial information and other
              information necessary to complete your transaction.
            </p>
            <p>
              We collect this information from you, from publicly available
              sources, and from our Third-Party Service Providers and other
              third parties, and do so for the purpose of completing
              transactions into which you have entered, or to provide you with
              information about our goods and services. Depending on the nature
              of your interaction with Neu, we also may be required by law to
              collect this information.
            </p>
            <p>
              At times, we may share Identifiers with our Third-Party Service
              Providers, our Affiliates, or other third parties to complete the
              transactions into which you have entered. In addition, we may
              share Identifiers with our Third-Party Service Providers, our
              Affiliates or other third parties in order to showcase our goods
              and services or the products of our Affiliates or third parties
              that may be of interest to you.
            </p>
            <h3>Customer Records.</h3>
            <p>
              Certain Identifiers may also be included in our customer records.
              These are records of our business transactions and interactions
              with you, and often include your name, address, or other
              information collected during, and for the purpose of effectuating,
              those business transactions. We collect this information from you,
              or from our Third-Party Service Providers acting on our behalf.
              Our use of this information, and use by our Third-Party Service
              Providers with which it is shared, are identical to the way we
              handle "Identifiers," as described above.
            </p>
            <h3>Legally Protected Characteristics.</h3>
            <p>
              Depending on the nature of our interaction with you, we may be
              required under state or federal law to collect certain information
              related to legally protected characteristics. This may include
              your age, sex, gender, sexual orientation, race, or ethnicity. We
              collect this information from you, and maintain and disclose this
              information in order to confirm compliance with certain legal
              obligations, or otherwise provide you with information about our
              goods and services.
            </p>
            <h3>Commercial Information.</h3>
            <p>
              Depending on the nature of our interaction with you, we may
              collect certain commercial information related to you, including
              property records, records of past purchases involving Neu, its
              Affiliates, or our Third-Party Service Providers, or information
              that you provide to us through your online Accounts. We collect
              this information as a result of our transactions with you, either
              from you directly or from our Third-Party Service Providers acting
              on our behalf. We may also collect certain commercial information
              from publicly available sources in order to facilitate our
              transactions.
            </p>
            <p>
              We use this information to complete our transactions with you and
              to maintain a record of those transactions as is often required by
              applicable laws and regulations. We also use this information to
              provide you with information related to our goods and services
              that may be of interest to you. While we do not often share this
              information with third parties, at times we share this information
              with our Third-Party Service Providers, including financial
              institutions, payment processors, and other entities that assist
              us in facilitating our transactions with you. We may also share
              this information with Neu Affiliates, our analytics providers, and
              our interest-based advertising providers.
            </p>
            <h3>Internet Activity and Identifiers.</h3>
            <p>
              When you interact with Neu online we may collect certain
              information about you, including your browsing history, your
              interactions with our Sites, or advertisements, your IP Address,
              and other unique online identifiers that may be associated with
              you or your device. We obtain this information primarily from the
              use of Cookies and similar technologies, and we describe our use
              of Cookies in more detail elsewhere in this Privacy Policy.
            </p>
            <p>
              We collect this information in order to enhance your experience on
              our Sites, and to ensure the information we provide to you is
              relevant to your interests. We share this information with Neu
              Affiliates, and certain of our Third-Party Service Providers such
              as our analytics providers, and our interest-based advertising
              providers.
            </p>
            <h3>Geolocation Data.</h3>
            <p>
              You may provide certain location information to us as part of your
              inquiry into our goods and services, or as part of the home-buying
              process. In addition, if you interact with Neu through the use of
              your mobile device, we may obtain certain geolocation data from
              that interaction. We obtain this information through your mobile
              device's interactions with our mobile sites and applications.
            </p>
            <p>
              We obtain this information in order to more accurately provide you
              with information. For example, we may use your geolocation
              information to respond to your mobile request for home
              availability in your area. We share this information with Neu
              Affiliates, and certain of our Third-Party Service Providers such
              as our analytics providers, and our interest-based advertising
              providers.
            </p>
            <h3>Video or Audio Recordings.</h3>
            <p>
              Depending on the nature of your interaction with us, you may have
              provided us with video or audio recordings or consent to record
              our interactions with you. These may be recordings of your
              interactions with our customer representatives, voicemails you may
              leave us, or visits to our model homes. We collect video or audio
              recordings with your consent, or as permitted by applicable law.
              We use those recordings only for training purposes, security
              purposes, or to respond to your request. We may also use
              testimonial recordings for marketing purposes but will only do so
              with your consent.
            </p>
            <h3>Professional or Employment-Related Information.</h3>
            <p>
              Neu may collect certain professional or employment-related
              information about you, either as part of our business transactions
              with you, or in order to evaluate your candidacy for employment
              with Neu or one of our Affiliates. In this way, we collect
              professional information from you directly, or from our
              Third-Party Service Providers and for the specific purposes
              outlined during the collection.
            </p>
            <p>
              While we may share your professional or employment-related
              information with third parties as required by law or to facilitate
              our transactions with you, we do not share your professional or
              employment-related information with third parties for other
              purposes. We may share this information with our Third-Party
              Service Providers or Affiliates, but otherwise do not share this
              information with other third parties.
            </p>
            <h3>Educational Information.</h3>
            <p>
              Neu may collect certain educational information about you in order
              to evaluate your candidacy for employment with Neu or one of our
              Affiliates or as part of our business transaction with you. In
              this way, we collect educational information from you directly, or
              from our Third-Party Service Providers and for the specific
              purposes outlined during the collection.
            </p>
            <p>
              While we may share your educational information with our
              Affiliates and third parties, such as our Third-Party Service
              Providers, to facilitate our transactions with you, we do not
              share your educational information with third parties for other
              purposes.
            </p>
            <h2>OTHER INFORMATION WE COLLECT.</h2>
            <p>
              <strong>Anonymous Information.</strong> Other information that we
              collect is Anonymous Information – that is, information that does
              not include Personal Information, or from which Personal
              Information has been deleted. Anonymous Information does not
              identify, and cannot be used to reasonably determine, the identity
              of, an individual. Anonymous Information may include demographic
              and other general information; it may include some, but not all,
              Automated Information collected through Cookies, including pixels
              and other technologies such as browser and other device
              information; and it may include high-level, non-specific location
              information and other information collected from your mobile
              devices.
            </p>
            <p>
              Aggregate Information. We may also gather Anonymous Information of
              many individuals and combine that information. Aggregate
              Information does not identify, and cannot be used to reasonably
              determine, the identity of, an individual. Aggregate Information
              is about groups or categories of visitors rather than individual
              visitors.
            </p>
            <h2>USE OF COOKIES.</h2>
            <p>
              Our Sites use Cookies in a variety of ways. This section explains
              Cookies, how they are used, and how you may exercise control over
              the types of Cookies Neu may use while you visit our Sites.
            </p>
            <h3>Types of Cookies.</h3>
            <p>
              We use Cookies to provide, protect, and improve our products and
              services, such as by personalizing content, offering and measuring
              advertisements, understanding user behavior, and providing a safer
              experience. We describe below the various types of Cookies we use
              and the purposes they perform. Please note that the specific
              Cookies we may use vary depending on the specific websites and
              services you use. If you are a resident of the European Economic
              Area, only essential Cookies are used, without your consent, when
              you visit our Sites.
            </p>
            <p>
              <strong>Essential Cookies:</strong> These Cookies are strictly
              necessary to provide you with our Sites and services and to enable
              essential features. If you disable these Cookies, we will not be
              able to fulfill your requests.
            </p>
            <p>
              <strong>Performance and functionality Cookies:</strong> These
              Cookies collect information about how you use our Sites and
              services and allow us to remember the choices you make while
              browsing. The information these Cookies collect allows us to
              optimize our Sites and make them easier for you to use, and it
              does not personally identify you. If you disable or opt out of
              these Cookies, you may not be able to use certain features of our
              Sites, applications, and services, and it may reduce the support
              or information that we can provide you.
            </p>
            <p>
              <strong>Analytics and customization Cookies:</strong> These
              Cookies collect information we use in aggregate form to help us
              understand how our Sites, applications and services are being used
              and how effective our marketing campaigns are, and to help us
              customize our Sites. If you disable or opt out of these Cookies,
              you may not be able to use certain features of our Sites,
              applications and services, and it may reduce the support or
              information that we can provide you.
            </p>
            <p>
              <strong>Advertising Cookies:</strong> These Cookies collect
              information about your browsing history and are used to make
              advertising messages more relevant to you. They perform functions
              like preventing the same advertisement from continuously
              reappearing, ensuring that advertisements are properly displayed,
              and in some cases selecting advertisements that are based on your
              interests. We may share this information with third parties to
              help create and deliver advertising personalized to you and your
              interests. If you disable or opt out of these Cookies, you may not
              be able to use certain features of our Sites, applications and
              services, and it may reduce the support or information that we can
              provide you.
            </p>
            <p>
              <strong>Social networking Cookies:</strong> These Cookies are used
              to enable you to share pages and content on our Sites and services
              through third-party social networking and other websites. These
              Cookies may also be used for advertising purposes.
            </p>
            <h3>Cookies Placed by Third Parties.</h3>
            <p>
              You may also encounter Cookies on our Sites, applications and
              services that are placed by third parties. We may also allow third
              parties to place Cookies on our Sites to track information about
              your online activities and/or across third-party sites or online
              services, including to send you targeted advertisements based on
              that information, which may include the remarketing of our
              products and services that you have viewed on our Sites and on
              third-party websites. If you are a resident of the European
              Economic Area, we do not permit the placement of non-essential
              third-party Cookies without your consent.
            </p>
            <p>
              This Cookies section does not apply to the Cookies, applications,
              technologies, or websites that are owned and/or operated by third
              parties, or such third parties' practices, even if they use or
              access our technology to store or collect information. To
              understand how third parties use Cookies, please review their
              privacy and cookie policies.
            </p>
            <h2>HOW WE COLLECT INFORMATION.</h2>
            <p>
              The information we collect varies depending on how you interact
              with us or others:
            </p>
            <p>
              <strong>Information You Provide to Us.</strong> You may provide
              information to us online for any number of reasons. For example,
              you may sign your home purchase agreement or lease over the
              Internet, request information about our homes or communities, talk
              to us via videoconference, make notes about our homes or
              communities, submit a warranty request, respond to a survey,
              submit a job or rental application, interact on our Social Media
              Pages or with our Mobile Applications, or subscribe to promotional
              emails. In such cases, we, or our Third-Party Service Providers,
              will collect the information that you provide, which may include
              information regarding your Neu home, your name, telephone number,
              email address, physical address, name of your present community or
              a Neu Community in which you are interested, certain demographic
              information, such as your age range and areas of interest, your
              payment card or banking account information if you are making a
              payment, and, in the case of a job application, your resume and
              social security number. In addition, you may provide information
              to us in person, for example, when you visit a model home. Unless
              prohibited by applicable law, we may combine information that you
              provide to us online with information that you provide to us in
              person, with publicly available information or with information
              provided to us by third parties such as social media platforms and
              data aggregators.
            </p>
            <p>
              <strong>Email.</strong> If you provide your email address to us
              and request emails regarding Neu products and services, you will
              receive commercial emails from us marketing our products and
              services, and we will collect your email address, other
              information that you provide, as well as Automated Information
              that we may collect. You may revoke your consent to receive
              commercial emails from Neu marketing our products and services by
              clicking the "unsubscribe" button in marketing email
              communications to you. Please be aware that changes to Your
              Privacy Choices may not be immediate. We will implement your
              changes as quickly as practicable, but in all cases, in accordance
              with timeframes required by applicable law and in no more than 30
              days.
            </p>
            <p>
              <strong>Our Mobile Applications.</strong> This Privacy Policy is
              displayed, in whole, on all of our Mobile Applications and is
              applicable to our collection, use and sharing of the information,
              including the Personal Information, that we may collect when you
              use your mobile devices to interact with our Mobile Applications.
              When you interact with our Mobile Applications, we collect the
              information that you provide to us, Automated Information, and
              location information that is provided by your mobile device
              interacting with our Sites, if you consent to sharing such
              location information with us.
            </p>
            <p>
              <strong>Information from Third Parties.</strong> We may collect or
              purchase information from third parties, including marketing
              companies, digital advertising agencies, third-party ad servers,
              third-party email servers, data aggregators, analytics providers,
              investor relations firms or third-party advertising networks.
              Certain of this information may be Automated Information that
              these third parties have gathered as they have tracked you across
              the Internet and over time. If you have responded to, or
              interacted with, third parties that share your information with
              us, we collect the information the third parties provide to us,
              including your email address.
            </p>
            <p>
              <strong>Your Online Accounts.</strong> Visitors to our Sites have
              the ability to create online Accounts. Through your online
              Accounts, you may search for a home to purchase or rent, start
              your purchase on certain homes, submit a rental application, and
              submit rent payments. When you provide information to us to
              perform those functions and others that are available through your
              Accounts, we collect that information subject to this Privacy
              Policy.
            </p>
            <p>
              <strong>Our Social Media Pages.</strong> Neu maintains Social
              Media Pages on social media platforms including, Facebook,
              Twitter, LinkedIn, and Instagram. You may participate on our
              Social Media Pages by posting and chatting. Neu may collect the
              information that you post or send to us including Personal
              Information, pictures or other information. It is important to
              remember that information posted on our Social Media Pages may
              become public or may be separately collected by third-party social
              media sites or platforms. In addition, our Sites provide social
              media features like "Follow," "Like," "Share" and "Link" buttons
              that collect your IP Address, and may place Cookies on your device
              in order to allow the functionality to perform properly. Also,
              depending upon how you link your social media account to other
              accounts and our Sites, or if you chose to send messages to your
              friends via our Social Media Pages, certain Personal Information
              from your social media account may be shared with us, which may
              include Personal Information from your profile or your friends'
              profiles. By linking to our Sites in such a manner, or sending
              messages to your friends through our Social Media Pages, you are
              indicating to us that you have the right to share contact
              information about your friends. You should always remember that
              the information that you post on our Social Media Pages is subject
              not only to this Privacy Policy, but also the privacy policies and
              Terms of Use of the respective third-party social media site or
              platform, and in accordance with those third-party privacy
              policies, may be collected by those sites and platforms, and may
              be made public. This Privacy Policy will be posted on those Social
              Media Pages where it solely governs our collection, use and
              sharing of your information.
            </p>
            <p>
              <strong>Location Information.</strong> We collect location
              information that you may enter when using our Sites such as your
              physical address, including your zip code. We also may collect
              Automated Information, including your location indicated by your
              IP Address, that may be provided by your internet service provider
              or ISP. Finally, we may collect your location from your mobile
              device when you interact with our Mobile Applications, if you
              consent to sharing your location with Neu. If you reside in the
              European Economic Area, we only process your location information
              from your mobile device with your consent.
            </p>
            <p>
              <strong>Automated Information.</strong> We and our Third-Party
              Service Providers have automated means of collecting information
              from you when you visit our Sites, or otherwise interact with us
              online. The information that is collected using these automated
              technologies does not identify you personally, but instead is
              linked to an anonymous numerical identifier. Consequently, except
              as otherwise required by applicable law, we use and disclose this
              Automated Information as Anonymous Information rather than
              Personal Information.
            </p>
            <p>
              <strong>Through the Use of Cookies.</strong> Cookies, pixels,
              clear GIFs, or other tracing and tracking technologies are small
              data files that a website or its service providers may place on
              your browser when you visit a website. They are widely used across
              the Internet to make websites work more efficiently and
              effectively, and for a variety of other purposes such as to assist
              in sending you interest-based advertisements, to provide
              personalized content, and to provide a safer experience. We
              provide more information about Cookies, including the different
              types of Cookies that you may encounter on the Internet, elsewhere
              in this Privacy Policy.
            </p>
            <p>
              <strong>
                Through the Use of Web Beacons and Other Similar Technologies.
              </strong>
              Neu and its Third-Party Service Providers may use Web Beacons
              (also known as GIFs or clear GIFs) and similar technologies that
              are electronic images that may recognize cookies on your browser
              when you view a website or an email. The use of data collected by
              such technologies assists us in measuring the success of our
              advertising campaigns, understanding how many viewers open an
              email message, and understanding the use of our Sites, including
              compiling analytics about its use. We may allow our Third-Party
              Service Providers to collect and compile Anonymous Information and
              Aggregate Information from the use of Web Beacons on our Sites to
              determine the efficiency of our Sites and the effectiveness of our
              marketing campaigns.
            </p>
            <p>
              <strong>Through Your Browser.</strong> Some information is
              collected automatically from your browser, such as your Media
              Access Control or "MAC address," your computer type, operating
              system and version number, device manufacturer, Internet browser
              and version number, and the name and version of the websites you
              visit and applications you are using. We use this information to
              assist us in enhancing and correcting the performance of our
              Sites. To the extent any this information is collected through the
              use of "non-essential cookies," if you reside in the European
              Economic Area, we only collect this information with your consent.
            </p>
            <p>
              <strong>Through Your Use of Your Mobile Device.</strong> If you
              are using a mobile device to interact with our Mobile
              Applications, we may collect information about your online
              behavior utilizing your device's Mobile Advertising Identifier or
              other temporary numeric identifier. This allows us to follow your
              online behavior without accessing your mobile device's permanent
              identifier. As part of the collection of Automated Information
              from your mobile device, we may collect information about your
              physical location. We use this information to provide you with
              geographically based material, such as when you search for a home
              on a mobile device. We will provide information to you regarding
              Neu communities that are close to you. We may share your physical
              location with our Third-Party Service Providers so that they may
              combine this information with other information about your
              browsing behavior such as the advertisements that you viewed. This
              allows us and our Third-Party Service Providers to share with you
              information that is of interest to you. This information may also
              be used by Third-Party Service Providers to track you across the
              devices that you use. Most mobile devices contain built in privacy
              controls that will allow you to opt-out of interest-based
              advertising or cross application advertising. Most also have
              functionality that will allow you to change your Mobile
              Advertising Identifier.
            </p>
            <p>
              <strong>Through Our Server Logs.</strong> Your IP Address is
              automatically logged to our server logs whenever you visit our
              Sites, together with the date and time of your visit and the pages
              on our Sites that you visit. Your IP Address is a unique numerical
              identifier that is assigned to your device by your ISP. Collecting
              IP Addresses is standard practice for website operators across the
              Internet. We use IP Addresses to assist us in enhancing and
              correcting the performance of our Sites. We use and disclose IP
              Addresses in accordance with applicable law.
            </p>
            <p>
              <strong>Through an Application That You Use.</strong> You may use
              a program or service that we operate or that one of our
              Third-Party Service Providers operates on our behalf that is
              displayed on any one of our Sites. When you use the application,
              we or our Third-Party Service Providers may collect information
              about your usage of the application. We use this information to
              improve the functionality of our applications and to determine
              usage patterns.
            </p>
            <p>
              <strong>Through Devices Installed in Your Home.</strong> Devices
              installed in your home may collect information about you and your
              use of the device. These devices may include, for example, a video
              doorbell, a door lock, a programmable thermostat, a smart light
              switch, a touchscreen smart home control device, home security
              devices such as door and window contacts and motion detectors, a
              garage door opener, and a smart speaker, all of which are
              integrated by a home automation platform. Certain of the
              information collected by these devices may be shared over the
              Internet with the third‑party providers of the devices and will be
              used by these companies in accordance with their own privacy
              policies and practices.
            </p>
            <h2>HOW WE USE INFORMATION.</h2>
            <p>
              Generally speaking, we use Personal Information and Anonymous
              Information to provide, improve, and develop our products and
              services, to communicate with you, to offer you targeted
              advertisements and services, and to protect us and our users.
            </p>
            <p>
              While we will use the information we collect for any lawful
              purpose we deem appropriate, more specifically, we use the
              information we collect for the following purposes:
            </p>
            <p>
              <strong>Communicating with you:</strong> We may use Personal
              Information to respond to and fulfill your requests, to complete
              the transactions you have requested, to provide updates and
              announcements about our products and services, to send information
              to you regarding our Sites and to support direct marketing and
              other opportunities of Neu and third parties as described in this
              Privacy Policy. In this regard, we may contact you by email,
              postal mail, text message, social media, telephone or other means
              regarding our products, services, promotions and employment
              opportunities.
            </p>
            <p>
              <strong>
                Providing, improving, and developing our products and services:
              </strong>
              We use Personal Information and Anonymous Information to help us
              provide, improve, and develop our products, services, and
              advertising. This includes using Personal Information for purposes
              such as data analysis, research, and audits. Such use and
              processing is based on our legitimate interest in offering you
              products and services and for business continuity.
            </p>
            <p>
              <strong>
                Offering and measuring targeted advertisements and services:
              </strong>
              We may use Personal Information to personalize your experience
              with our products and services, on third-party websites and
              applications, and to determine the effectiveness of our
              promotional campaigns.
            </p>
            <p>
              <strong>Promoting safety and security:</strong> We use Personal
              Information to help verify user activity, as well as to promote
              safety and security, such as by monitoring fraud and investigating
              suspicious or potentially illegal activity or violations of our
              terms or policies, and to comply with any applicable law,
              regulation, legal process, or governmental request. Such use and
              processing is based on our legitimate interest in helping ensure
              the safety of our company and users who interact with us online.
            </p>
            <p>
              Neu will utilize all information that you share with us or that we
              collect in accordance with this Privacy Policy and applicable law.
            </p>
            <p>
              If you use or visit our Sites, submit content to us, or post or
              submit any content on this Website or our other Sites such as our
              Social Media Pages, you agree that, with respect to such content,
              Neu is granted an unlimited, non-exclusive, world-wide,
              non-revocable, perpetual license to reproduce, duplicate,
              transmit, display, publish, create derivative works, sublicense
              and otherwise use any such information for any lawful purpose
              whatsoever, including marketing and you agree to hold us harmless
              with respect thereto.
            </p>
            <h2>HOW WE SHARE INFORMATION WE COLLECT.</h2>
            <p>
              We do not share your Personal Information with any third parties
              except in accordance with this Privacy Policy and Your Privacy
              Choices. In this regard, to the extent permitted by applicable law
              and Your Privacy Choices, Neu discloses information including your
              Personal Information that we collect online as well as information
              that we may have collected about you in person, without notice to
              you, to the following categories of third parties:
            </p>
            <p>
              <strong>Neu Affiliates.</strong> Neu and its Affiliates may share
              your information, including your Personal Information, with one
              another. Such sharing may be for the direct marketing purpose of
              Neu or its Affiliates. Neu is the party responsible for the
              management of your Personal Information that is jointly used by
              our Affiliates.
            </p>
            <p>
              <strong>
                Third-Party Service Providers Providing Services to Neu and Neu
                Customers.
              </strong>
              If you are a Neu customer or prospective customer, we share your
              information, which may include Personal Information, with our,
              Third-Party Service Providers, including our subcontractors,
              financial institutions, payment processors (for example, our
              payment card and ACH processors) and developers of communities
              (for example, electricians, painters, land developers and telecom
              providers), in connection with your purchase or rental of a Neu
              home, in response to your warranty requests, to complete
              transactions that you have initiated such as, but not limited to,
              customer service requests, and payment transactions using your
              payment card or banking information, and to provide services to
              you and our other customers. We may share your information with
              our Third-Party Service Providers that perform services on our
              behalf, such as those that perform technology services for us such
              as website hosting, email delivery, and electronic signature
              services.
            </p>
            <p>
              <strong>Financial Institutions and Payment Processors.</strong> If
              you initiate a transaction, such as making a home purchase or
              rental deposit or monthly rental payment, your payment card or
              banking information will be transmitted to, and shared with, the
              Third-Party Service Providers processing electronic payments for
              us as well as the financial institutions and other Third-Party
              Service Providers involved in processing your payment. Your
              information will be subject to this Privacy Policy and the
              security safeguards implemented by Neu, contractual limitations on
              use and disclosure included by Neu in its agreements with its
              Third-Party Service Providers processing electronic payments, as
              well as the security practices and safeguards of the financial
              institutions involved in processing your payment. We retain no
              payment card information. With regard to ACH transactions, in
              accordance with requirements issued by the National Automated
              Clearing House Association or "NACHA," we will retain your written
              authorization, authorizing us to process an ACH transaction, which
              will include certain of your banking account information. We will
              retain no other information generated or input by you in your ACH
              transaction.
            </p>
            <p>
              <strong>Homeowner Associations.</strong> If you purchase or rent a
              home from us in a community with a homeowner association, we share
              your Personal Information with the homeowner association so that
              it may perform its responsibilities, such as collecting dues and
              sending notices.
            </p>
            <p>
              <strong>Our Rental Communities.</strong> With respect to homes
              that we rent, we may share your Personal Information with
              Third-Party Service Providers that perform leasing and property
              management services for our communities. Such services include
              designing and hosting community websites, implementing and hosting
              applicant and tenant portals, managing leasing applications,
              screening potential tenants, and performing other leasing and
              property management services. In addition, through our Sites, we
              may provide you access to websites of our Third-Party Service
              Providers to allow you to perform certain activities online such
              as submitting an application to rent, making rental payments, or
              requesting maintenance for your home. The collection and use of
              your Personal Information by our Third-Party Service Providers,
              including on their websites, is governed by their own privacy
              policies and contractual limitations that we may include in their
              agreements with Neu.
            </p>
            <p>
              <strong>Our Analytics Providers.</strong> Certain of our
              Third-Party Service Providers assist us in analyzing Automated
              Information that we, and they, collect regarding the online
              behavior of visitors to our Sites. Automated Information collected
              through cookies assists us in analyzing, for example, which
              communities and homes visitors have viewed, which pages of our
              Sites are of most interest, what promotions visitors have enjoyed
              and the success of our advertising campaigns.
            </p>
            <p>
              <strong>Our Interest-Based Advertising Providers.</strong> Neu
              utilizes Third-Party Service Providers to deliver advertisements
              including interest-based advertisements to you based on your
              previous online behavior, including your browsing history, as well
              as other Automated Information that they have collected about you
              while you have visited websites and utilized applications,
              including our own. Our interest-based advertisements may be
              delivered to you on our Sites, across the Internet and across your
              devices.
            </p>
            <p>
              <strong>Legal Matters.</strong> It may be necessary - by law or as
              a result of legal process, litigation, or requests from public or
              governmental authorities within or outside your country of
              residence - for us to disclose Personal Information. We may also
              disclose Personal Information if we determine that for purposes of
              national security, law enforcement, or other issues of public
              importance, disclosure is necessary or appropriate.
            </p>
            <p>
              We may also disclose Personal Information if we determine in good
              faith that disclosure is reasonably necessary to protect our
              rights and pursue available remedies, enforce our terms and
              conditions, investigate fraud, or protect our operations or users.
            </p>
            <p>
              <strong>To Investigate and Prevent Suspicious Activity.</strong>{' '}
              We may monitor your use of our Sites or use information that we
              have collected about you, including Personal Information, to,
              identify fraudulent activities and transactions, prevent
              violations of our Terms of Use that govern our Sites, and prevent
              and prosecute misuse of our Sites. Our efforts in this regard may
              result in the collection and analysis of additional information
              about your online behavior that we may share with our Third-Party
              Service Providers that are assisting us in our security efforts.
            </p>
            <p>
              <strong>Business Transactions.</strong> We may share your
              information, including your Personal Information, with potential
              or actual buyers or other parties to the transaction in the event
              of a potential or actual sale, spin-off, merger, or similar
              corporate transaction involving a business unit of Neu.
            </p>
            <p>
              <strong>Storage.</strong> We may share your information, including
              your Personal Information, with third parties for the purpose of
              helping us gather, store and maintain data.
            </p>
            <p>
              <strong>Parties with Which We Co-brand.</strong> Some Neu products
              and services may be promoted with other companies, including our
              Affiliates. If you click on links in these advertisements or
              register to receive more information about the co-branded products
              and services, your information, including your Personal
              Information, will be shared by Neu with the other company
              associated with the co-branded advertisement. The information will
              be collected, used and shard in accordance with each company's
              privacy policy and other agreements with you, including Your
              Privacy Choices.
            </p>
            <h2>
              THIRD-PARTY COLLECTION OF PERSONAL INFORMATION ABOUT YOUR ONLINE
              ACTIVITIES OVER TIME AND ACROSS DIFFERENT WEBSITES.
            </h2>
            <p>
              We allow certain third parties to collect information, including
              Personal Information, about your online activities on our Sites,
              and on and across different websites after you leave our Sites. In
              addition, other third parties with which we have no business
              relationship may be tracking your online behavior across the
              Internet including when you visit our Sites.
            </p>
            <h2>TEXT MESSAGES.</h2>
            <p>
              We may offer you the ability to receive SMS text messages from us,
              including those that are sent using automated means. We send text
              messages with your consent, and they are not a condition of any
              purchase. Your mobile carrier's message and data rates may apply.
              You may choose to stop receiving text messages from us by replying
              STOP.
            </p>
            <h2>DO NOT TRACK SIGNALS.</h2>
            <p>
              Some web browsers may give you the ability to enable "Do Not
              Track" functionality. "Do Not Track" is a browser setting that
              requests that web applications eliminate their tracking of you.
              When you choose to turn on your "Do Not Track" setting, your
              browser sends a signal to third-party ad servers, digital
              advertising networks, data aggregators and others to stop tracking
              you. This is different than disabling cookies on your browser.
              Browsers with the "Do Not Track" functionality enabled continue to
              have the capability to accept cookies. There is currently no
              agreement or consensus on how companies should interpret a "Do Not
              Track" signal. Consequently, we do not honor "Do Not Track"
              signals or other mechanisms that provide you with the ability to
              exercise choice regarding the collection of information (including
              your Personal Information) relating to your online activities over
              time and across third-party websites or online services.
            </p>
            <h2>SOCIAL SECURITY NUMBER PRIVACY POLICY.</h2>
            <p>
              Neu, as an employer, in the normal course of business and as
              required by law, collects social security numbers. In connection
              with accepting job applications, we will collect Personal
              Information from prospective new employees, including their social
              security number. This information is transmitted over a secure
              connection that implements encryption of the Personal Information
              being transmitted. In addition to secure connections and
              encryption for collection, we maintain other reasonable measures
              to protect and limit access to social security numbers on a
              need-to-know basis. In addition, we prohibit their unlawful
              disclosure. We maintain reasonable procedures to destroy, erase,
              or make unreadable any data, computer files and documents
              containing social security numbers prior to their disposal.
              Failure to follow these procedures by any employee may subject the
              employee to disciplinary action up to, and including, termination.
            </p>
            <h2>CHANGES TO OUR PRIVACY POLICY.</h2>
            <p>
              Neu reserves the right to change this Privacy Policy, from time to
              time and as required by applicable law. We may periodically change
              this Privacy Policy to keep pace with new technologies, industry
              practices, and regulatory requirements, among other reasons. Any
              non-material changes will take effect immediately upon posting of
              an updated Privacy Policy. There may, however, be cases where
              changes to the Privacy Policy may be more significant. In such
              cases, we will provide prominent notice of such changes before
              they take effect.
            </p>
            <p>
              Where applicable, your continued use of our products, services and
              Sites after the effective date of the Privacy Policy means that
              you accept the revised Privacy Policy.
            </p>
            <h2>YOUR PRIVACY RIGHTS.</h2>
            <p>
              We take reasonable steps to ensure that your Personal Information
              is accurate, complete, and up-to-date. Depending on where you
              live, you may have some or all of the rights described below.
            </p>
            <h3>Your California Privacy Rights.</h3>
            <p>
              Because Neu provides all of its customers, including its
              California customers, with a cost-free method to opt-out of the
              sharing of Personal Information with all third parties, including
              our Affiliates, for the direct marketing of those third parties,
              and does not disclose to third parties, including our Affiliates,
              for their direct marketing purposes the Personal Information of
              any customer if the customer has exercised the option to prevent
              such disclosure, and because Neu discloses these policies in this
              Privacy Policy, pursuant to California Civil Code section
              1798.83(c)(2), Neu is in compliance with California's "Shine the
              Light" law.
            </p>
            <p>
              California residents may have further rights related to the
              handling of their Personal Information under the California
              Consumer Privacy Act. Neu may share your Personal Information with
              third parties that perform or market services at your home and in
              our communities and/or that market products you may purchase for
              your home. In return, Neu may receive a monetary benefit. Such
              sharing may constitute a sale under applicable law. Neu extends
              California residents certain rights related to this information.
              California residents also have the right to not be treated
              differently by Neu in response to your decision to exercise any of
              these rights.
            </p>
            <h2>LINKS TO OTHER WEBSITES.</h2>
            <p>
              We may provide links from our Sites, through advertisements or
              otherwise, to third-party websites, or applications including
              various social media platforms. Those websites, applications and
              social media platforms have their own privacy policies and
              practices. This Privacy Policy does not address, and we are not
              responsible for, their content, privacy policies or practices or
              security and safeguarding practices. Any Personal Information that
              you provide on these other websites, or to such other applications
              including Personal Information that you may post on social media
              platforms will be subject to the privacy policies and practices of
              the third party. The inclusion of a link on any of our Sites does
              not imply endorsement of the linked website or application by us
              or any of our affiliates. We are not responsible for the
              collection, usage or sharing practices or policies of other
              companies or platforms including Facebook, Twitter, LinkedIn,
              Instagram, Google, or Adobe, to mention just a few. Please visit
              those websites to review their privacy policies.
            </p>
            <h2>CHILDREN.</h2>
            <p>
              Our Sites contain general information not directed towards
              children. We do not knowingly collect information from children
              under the age of 13, or the equivalent minimum age depending on
              the jurisdiction. In this regard, our Sites are general audience
              sites that are not designed nor intended to collect Personal
              Information from children under the age of 13. We do not sell the
              information of children under the age of 16 without affirmative
              authorization by the child's parent or legal guardian.
            </p>
            <h2>USE OF OUR WEBSITE IN OTHER COUNTRIES.</h2>
            <p>
              If you choose to access one of our Sites, including this Website,
              from a country other than the United States, you acknowledge that
              our Sites are operated in the United States. Information that you
              share with us through our Sites or which is collected about you or
              your online behavior will be transferred to and processed in the
              United States. By using our Sites, you consent to this transfer,
              storage and processing of your information in the United States
              where the privacy laws may be different, including more or less
              protective, than the country in which you are using our Sites.
            </p>
            <h2>CROSS BORDER TRANSFER.</h2>
            <p>
              When we share your personal information with our partners,
              affiliated companies, and Third-Party Service Providers, your
              personal information may be transferred to and/or made accessible
              from countries outside of the European Economic Area. Where
              required, we will enter into model contractual clauses as adopted
              by the European Commission, or rely on alternative legal bases,
              where applicable, or binding corporate rules where our partners or
              Third-Party Service Providers have adopted such internal policies
              approved by European data protection authorities.
            </p>
            <h2>EMPLOYMENT.</h2>
            <p>
              We provide the opportunity on our Sites for you to review
              employment opportunities with us and our Affiliates and to submit
              online an employment application. If you choose to do so, the laws
              and regulations of the United States will apply to the collection
              and use of all information that you provide.
            </p>
            <h2>SECURITY.</h2>
            <p>
              Neu has implemented safeguards as required by law to protect your
              Personal Information. Unfortunately, however, no information or
              data transmitted electronically, including information or data
              transmitted over the Internet or stored electronically can be
              assumed to be safe and secure. If you believe that the security of
              any information or data that you have provided to us has been
              compromised, please immediately notify us of the problem by
              contacting us in accordance with the "Contacting Us" section
              below.
            </p>
            <h2>CONTACTING US.</h2>
            <p>
              If you have any questions, concerns or suggestions about our
              Privacy Policy, you may contact us by email at{' '}
              <a href='mailto:info@neucommunity.com'>info@neucommunity.com</a>.
            </p>
            <h2>EFFECTIVE DATE.</h2>
            <p>
              We may occasionally update this Privacy Statement. When we do, we
              will revise the “last updated” date at the top of the Privacy
              Statement and take such additional steps as may be required by
              law.
            </p>
          </TextBlock>
        </Container>
      </Layout>
    </>
  );
};
const Container = styled.section`
  padding: var(--body-padding-y) var(--body-padding-x);
  a:hover {
    color: ${colors.blue};
    text-decoration: underline;
  }
`;

export default PrivacyPage;
